import { ButtonV2, CardV2, GlobalStyles, Icon, JobProgress as JobProgressWidget, StackV2, View } from '@andromeda'
import { Types } from '@orbit'
import { useAppDispatch } from '@redux/hooks'
import { listWidgetData } from '@redux/reducers/dashboardReducer'
import { TrackingEventEnums } from '@utils/tracking/enums'
import { trackEvent } from '@utils/tracking/helpers'
import { NavigationContext } from '@utils/context/Navigation.context'
import { WidgetEnums, iWidget } from '@utils/types/dasboard'
import { JobStatus } from '@utils/types/jobs'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { WidgetHeader } from './widget-header'

const STATUS_OPTIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Live',
    value: JobStatus.LIVE,
  },
  {
    label: 'Draft',
    value: JobStatus.DRAFT,
  },
  {
    label: 'Booked',
    value: JobStatus.BOOKED,
  },
  {
    label: 'Completed',
    value: JobStatus.COMPLETED,
  },
  {
    label: 'Archived',
    value: JobStatus.ARCHIVED,
  },
  {
    label: 'Cancelled',
    value: JobStatus.CANCELLED,
  },
]
const SORT_OPTION_PARAMS: { [key: string]: Pick<Types.Dashboard.iDashboardQueryParms, 'sortBy' | 'sortOrder'> } = {
  ['due-soonest']: { sortBy: 'start_datetime', sortOrder: 'asc' },
  ['due-furthest']: { sortBy: 'start_datetime', sortOrder: 'desc' },
  ['recently-updated']: { sortBy: 'updated_at', sortOrder: 'asc' },
  ['least-recently-updated']: { sortBy: 'updated_at', sortOrder: 'desc' },
}

const JobProgress = ({
  jobProgressData,
  jobProgressLoading,
  widgets,
  meta,
  role,
}: {
  jobProgressLoading: boolean
  jobProgressData?: Array<Types.Job.iJobList>
  widgets?: Array<iWidget>
  meta?: iWidget
  role?: Types.Roles.iRole
}) => {
  const dispatch = useAppDispatch() // For dispatching Actions
  const router = useRouter()
  const { workspace } = useContext(NavigationContext)

  const [page, setPage] = useState('1')
  const [data, setData] = useState(jobProgressData)
  const [jobStatus, setjobStatus] = useState(STATUS_OPTIONS[0])
  const [sorting, setSorting] = useState(SORT_OPTION_PARAMS['due-soonest'])
  const [isListLoading, setIsListLoading] = useState(false)

  useEffect(() => {
    setData(jobProgressData)
  }, [jobProgressData])

  useEffect(() => {
    if (role && meta && page !== '1') {
      dispatch(
        listWidgetData({
          role: role.eid,
          widget: meta.eid,
          job_statuses: jobStatus.value,
          page,
        })
      ).then((res) => {
        setData((prev) => [...(prev ? prev : []), ...res.payload.data])
      })
    }
    // for lazy loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setIsListLoading(true)
    if (role && meta) {
      dispatch(
        listWidgetData({
          role: role.eid,
          widget: meta.eid,
          job_statuses: jobStatus.value,
          page: '1',
          ...sorting,
        })
      )
        .then((res) => {
          setData(res.payload.data)
          setPage('1')
        })
        .finally(() => setIsListLoading(false))
    }
  }, [dispatch, jobStatus.value, meta, role, sorting])

  if (jobProgressLoading) {
    return (
      <View style={{ flex: 1, width: '100%' }}>
        <View style={{ flex: 1 }}>
          <SkeletonTheme height={30} baseColor={GlobalStyles.SLATE_100} highlightColor={GlobalStyles.SLATE_200}>
            <Skeleton style={{ width: 100 }} count={1} />
            <Skeleton count={6} />
          </SkeletonTheme>
        </View>
      </View>
    )
  }

  if (!data || (widgets && !widgets.some((widget) => widget.type === WidgetEnums.JOB_PROGRESS))) return null

  return (
    <StackV2 flex={1} justifyContent="space-between">
      <WidgetHeader
        IconComponent={(props) => <Icon.Layers {...props} />}
        title="Jobs Progress"
        headerButtons={
          <StackV2 width={'auto'} justifyContent={'flex-end'}>
            <ButtonV2
              variant={'outlined'}
              onClick={() => {
                trackEvent({
                  event: TrackingEventEnums.Dashboard.DASHBOARD_VIEW_MORE,
                  eventProperties: {
                    page_name: 'dashboard',
                    section_name: 'Jobs Progress',
                    component_name: 'View All Jobs Button',
                    workspace: workspace,
                  },
                })
                router.push(`${router.asPath}/jobs`)
              }}
              endIcon={<Icon.ChevronRight color={GlobalStyles.PRIMARY_500} size={GlobalStyles.FONT_SIZES.SMALL} />}>
              View All Jobs
            </ButtonV2>
          </StackV2>
        }
      />
      <CardV2 className="widget-content" sx={{ maxHeight: '376px', height: '376px', boxSizing: 'border-box' }}>
        <JobProgressWidget
          onSortingChange={(val) => {
            setSorting(SORT_OPTION_PARAMS[val.label])
            trackEvent({
              event: TrackingEventEnums.Dashboard.DASHBOARD_REFINE,
              eventProperties: {
                page_name: 'dashboard',
                section_name: 'Jobs Progress',
                component_name: 'Sort by Dropdown',
                workspace: workspace,
              },
            })
          }}
          onStatusChange={(val) => {
            trackEvent({
              event: TrackingEventEnums.Dashboard.DASHBOARD_REFINE,
              eventProperties: {
                page_name: 'dashboard',
                section_name: 'Jobs Progress',
                component_name: 'Status change Dropdown',
                workspace: workspace,
              },
            })
            setjobStatus(val)
          }}
          joblist={data}
          statusOptions={STATUS_OPTIONS}
          lazyLoad={() => {
            setPage((prev) => (parseInt(prev) + 1).toString())
          }}
          onClickItem={(job) => {
            router.push(`${router.asPath}/jobs/${(job as unknown as { eid: string }).eid}`)
          }}
          isLoading={isListLoading}
        />
      </CardV2>
    </StackV2>
  )
}

export { JobProgress }
